<template>
  <v-dialog
    v-model="showDialogSetPrice" fullscreen hide-overlay :contained="true" transition="dialog-bottom-transition"
  >
    <v-card class="linerp-layout">
      <div class="d-flex justify-space-between align-center px-6 pt-4">
        <page-title title="設定加價金額"/>
        <v-btn @click="handleDialogSetPriceSwitch(false)" text icon>
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>
      <v-container class="my-2">
        <v-row class="px-3 pb-2">
          <v-col cols="2" class="py-0 px-0 d-flex justify-start align-center font-weight-bold">屬性</v-col>
          <v-col cols="2" class="py-0 px-0 d-flex justify-center align-center font-weight-bold">單價</v-col>
          <v-col cols="1" class="py-0 px-0"></v-col>
          <v-col cols="4" class="py-0 px-0 d-flex justify-center align-center font-weight-bold">屬性加價</v-col>
          <v-col cols="1" class="py-0 px-0"></v-col>
          <v-col cols="2" class="py-0 px-0 d-flex justify-center align-center font-weight-bold">總單價</v-col>
        </v-row>
      </v-container>
      <template v-for="(attrV, attrI) in attrs">
        <v-container v-if="attrV.tags.length > 0" :key="attrI" class="my-2">
          <v-row class="px-3 pb-2">
            <v-col class="py-0 px-0 d-flex justify-start align-center font-weight-bold">
              <div>{{ attrV.level_name || '(無屬性名稱)' }}</div>
            </v-col>
          </v-row>
          <v-row v-for="(tagV, tagI) in attrV.tags" :key="tagI" class="px-3 pb-2">
            <v-col cols="2" class="py-0 px-0 d-flex justify-start align-center" style="word-break: break-all;">{{ tagV.name }}</v-col>
            <v-col cols="2" class="py-0 px-0 d-flex justify-center align-center" style="word-break: break-all;">{{ calculateResult }}</v-col>
            <v-col cols="1" class="py-0 px-0 d-flex justify-center align-center">+</v-col>
            <v-col cols="4" class="py-0 px-0 d-flex justify-center align-center">
              <v-text-field
                  dense
                  outlined
                  maxlength="10"
                  counter
                  placeholder="請輸入加價"
                  hide-details
                  class=""
                  v-model.number="tagV.add_price"
                  rounded
                  style="font-size: 14px;"
                  type="number"
                ></v-text-field>
            </v-col>
            <v-col cols="1" class="py-0 px-0 d-flex justify-center align-center">=</v-col>
            <v-col cols="2" class="py-0 px-0 d-flex justify-center align-center" style="word-break: break-all;">{{ Number(calculateResult) + (Number(tagV.add_price) || 0) }}</v-col>
          </v-row>
        </v-container>
      </template>
      <v-container>
        <v-btn color="primary" small width="100%" @click="handleDialogSetPriceFinish">
          設定完成
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialogSetPrice: {
      type: Boolean,
      required: true,
    },
    handleDialogSetPriceSwitch: {
      type: Function,
      required: true,
    },
    handleDialogSetPriceFinish: {
      type: Function,
      required: true,
    },
    attrs: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    calculateResult: {
      type: Number,
      required: true,
      default: () => {
        return 0
      }
    }
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
h1 {
  color: #42b983;
}
</style>
