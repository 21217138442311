<template>
  <v-dialog
    v-model="showDialogSetAttr" fullscreen hide-overlay :contained="true" transition="dialog-bottom-transition"
  >
    <v-card class="linerp-layout">
      <div class="d-flex justify-space-between align-center px-6 pt-4">
        <page-title title="設定屬性"/>
        <v-btn @click="handleDialogSetAttrSwitch(false)" text icon>
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </div>
      <v-container v-for="(attrV, attrI) in attrs" :key="attrI" class="mb-5">
        <v-row class="d-flex align-baseline">
          <v-col cols="3" class="py-0">
            <div>屬性{{ attrV.level }}</div>
          </v-col>
          <v-col cols="9" class="py-0 pl-0">
            <v-text-field
              dense
              outlined
              maxlength="10"
              counter
              :placeholder="attrV.level_placeholder"
              v-model="attrV.level_name"
              rounded
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 d-flex flex-wrap align-center">
            <div class="tag_input" v-for="(tagV, tagI) in attrV.tags" :key="tagI">
              <v-text-field
                dense
                outlined
                maxlength="10"
                counter
                placeholder="請輸入"
                hide-details
                class="mr-2 mb-2"
                v-model="tagV.name"
                rounded
              >
                <template v-slot:append>
                  <v-icon @click="removeTag(attrV.tags, tagI)" class="cursor-pointer mt-0">
                    ri-close-line
                  </v-icon>
                </template>
              </v-text-field>
            </div>
            <v-chip
              v-if="attrV.tags.length < 10"
              color="primary"
              class="mr-2 mb-2"
              rounded
              @click="addTag(attrV.tags)"
            >
              <template v-slot:default>
                <v-icon class="cursor-pointer mr-2">
                  ri-add-line
                </v-icon>
                <span>新增</span>
              </template>
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-btn
              rounded
              small
              color="primary lighten-1"
              style="width: 100%"
              @click="addItem()"
            >
              新增
            </v-btn>
            <!-- <v-chip
              color="primary"
              class="mr-2 mb-2 w-100"
              rounded
              @click="addItem()"
            >
              <template v-slot:default>
                <span>新增</span>
              </template>
            </v-chip> -->
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-btn color="primary" small width="100%" @click="handleDialogSetAttrNextStep">下一步，設定加價金額</v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialogSetAttr: {
      type: Boolean,
      required: true,
    },
    handleDialogSetAttrSwitch: {
      type: Function,
      required: true,
    },
    handleDialogSetAttrNextStep: {
      type: Function,
      required: true,
    },
    attrs: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      
    };
  },
  methods: {
    removeTag(list, tag){
      if(!Array.isArray(list)) return
      this.$delete(list, tag)
    },
    addTag(list){
      list = list.push({name: '', image: ''})
    },
    addItem(){
      this.attrs.push({level: this.attrs.length + 1, level_name: '', level_placeholder: '請輸入屬性名稱', tags: []})
    }
  },
};
</script>
<style lang="sass" scoped>
.tag_input
  max-width: 125px
  ::v-deep .v-input
    &__slot
      padding-right: 15px !important
      padding-left: 15px !important
    &__append-inner
      margin-top: 0 !important
</style>