<template>
  <v-container class="linerp-layout py-0">
    <v-row class="align-center">
      <v-col>
        <v-switch v-model="formData.is_attr" class="pt-3" dense color="green" inset></v-switch>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn color="primary" small @click="handleDialogSetAttrSwitch(true)">設定商品屬性</v-btn>
      </v-col>
    </v-row>
    <DialogSetAttr
      :showDialogSetAttr="showDialogSetAttr"
      :handleDialogSetAttrSwitch="handleDialogSetAttrSwitch"
      :handleDialogSetAttrNextStep="handleDialogSetAttrNextStep"
      :attrs="formData.attr"
    />
    <DialogSetPrice
      :showDialogSetPrice="showDialogSetPrice"
      :handleDialogSetPriceSwitch="handleDialogSetPriceSwitch"
      :handleDialogSetPriceFinish="handleDialogSetPriceFinish"
      :attrs="formData.attr"
      :calculateResult="calculateResult"
    />
  </v-container>
</template>

<script>
import DialogSetAttr from "./DialogSetAttr.vue";
import DialogSetPrice from "./DialogSetPrice.vue";
import formItemMixin from '@/components/form/mixins/formItemMixin'

export default {
  components: {
    DialogSetAttr,
    DialogSetPrice,
  },
  mixins: [formItemMixin],
  data() {
    return {
      model: true,
      showDialogSetAttr: false,
      showDialogSetPrice: false,
    };
  },
  computed: {
    calculateResult() {
      if (!this.formData.price) return null
      if (!this.formData.per_shipping_count) return null
      return this.formData.per_shipping_count * this.formData.price
    },
  },
  methods: {
    handleDialogSetAttrSwitch(status) {
      this.showDialogSetAttr = status
    },
    handleDialogSetAttrNextStep() {
      const allTags = this.formData.attr.flatMap(item => item.tags)
      const tagNames = allTags.map(tag => tag.name)
      const uniqueTagNames = new Set(tagNames)

      if (uniqueTagNames.size !== tagNames.length) {
        this.$snotify.error("屬性標籤名稱不能重複")
        return
      }
      this.showDialogSetPrice = true
    },
    handleDialogSetPriceSwitch(status) {
      this.showDialogSetPrice = status
    },
    handleDialogSetPriceFinish() {
      this.showDialogSetAttr = false
      this.showDialogSetPrice = false
    },
  },
};
</script>

<style scoped>
h1 {
  color: #42b983;
}
</style>
